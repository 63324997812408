@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login>div{
    padding: 1rem 0.8rem;
    border-radius: 10px;
}
.login .form-div{
    /* background: #cfe3f8; */
}
.login .headings{
    border-radius: 50rem;
    padding: 0.5rem 0.2rem;
    margin-bottom: 0.8rem;
    color: var(--bg-dark-blue);
}

.login .headings h1{
    font-family: "Poppins", sans-serif ;
    font-size: 2.5rem;
}
.login .headings p{
    margin-bottom: 0rem;
}
.login .input-field{
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.5rem;
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
}
.login .input-field i{
    flex-basis: 10%;
    color: var(--theme-color);
}
.login .input-field input{
    flex-basis: 78%;
    border: none;
    outline:none;
    background: transparent;
}
.login button[type="submit"]{
    color: white;
    padding: 0.3rem 0.2rem;
    text-align: center;
    background: var(--theme-color);
    text-transform: capitalize;
    width: 50%;
    margin: 1rem auto;
    border-radius: 50rem;
    font-weight: 500;
    font-size: 1.2rem;
}
.login a{
    color: var(--color-red);
}

.flex.width-80{
    width: 90%;
    margin: auto;
}
.resend-btn a{
    text-decoration: none;
    color: inherit;
}
.resend-btn a:hover{
    color: red;
}

@media only screen and (max-width: 520px){
    .login .input-field {
        width: 95%;
        gap: 0.5rem;
    }
}
@media only screen and (max-width: 420px){
    .login .input-field {
        width: 95%;
        gap: 0.5rem;
    }
    .login button[type="submit"] {
        width: 80%;
    }
    .login {
        padding: 0.5rem;
    }
    .login .flex.width-80{
        margin-top: 0.5rem !important;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
}











