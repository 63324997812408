/* Select and Option Styling */
.property__form .select-wrapper select.wide {
    width: 100% ;
    padding: 10px ;
    border: 2px solid var(--theme-color) ;
    background-color: var(--white-color) ;
    color: var(--higer-secondary-color) ;
    font-size: 16px ;
    border-radius: 5px ;
    appearance: none ;
    -webkit-appearance: none ;
    -moz-appearance: none ;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23202C38' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") ;
    background-repeat: no-repeat ;
    background-position: calc(100% - 10px) center ;
    background-size: 14px ;
}

.property__form .select-wrapper select.wide:focus {
    outline: none ;
    border-color: var(--primary-color) ;
}

.property__form .select-wrapper option {
    background-color: var(--white-color) ;
    color: var(--higer-secondary-color) ;
    padding: 10px ;
    font-size: 16px ;
}



.view-all-btn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: 2px solid var(--primary-color);
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.view-all-btn:hover {
    background-color: var(--white-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    transform: translateY(-3px);
}

.view-all-btn:active {
    background-color: var(--higer-secondary-color);
    color: var(--white-color);
    border: 2px solid var(--higer-secondary-color);
    transform: translateY(1px);
}

@media only screen and (max-width: 600px) {
    .tab-btns.tab-buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.2rem;
    }
    .banner__tab__section li.tab-btn {
        background: var(--primary-color);
        border-radius: 5px 5px 0px 0px;
        height: auto;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 7px 10px;
        cursor: pointer;
        margin-right: 0px;
    }
    .property__form .select-wrapper select.wide {
        padding: 0.2rem;
    }

}

@media only screen and (max-width: 420px){
    .banner__one .banner__one__content .banner__one__left {
        padding: 80px 0px 10px 0px;
    }
    .md-mb-5 {
        margin-bottom: auto;
    }
}