.allowed{
    cursor: pointer;
}
.not-allowed{
    cursor: not-allowed;
}



/* bootstrap inputs ===  */
.form-control , .form-select {
    border-color: var(--theme-color);
}
.form-control:focus , .form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--secondary-color);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(193, 210, 237, 0.25);
}
.post__thumb img{
    object-fit: cover;
    height: 100%;
}







/* ==================== Whatsapp =================  */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    /* font-size: 30px; */
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;

    transform: all .5s ease;
    background-color: #25d366;
    line-height: 50px;

}

.whatsapp_float::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background-color: #25d366;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
}

.whatsapp_float:focus {
    border: none;
    outline: none;
}

.whatsapp-icon {
    margin-top: 16px;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 1;
    }
}



.main-header .upper-head a , 
.main-header .upper-head span{
    color: white;
}
.main-header.style-three .main-menu .navigation > li > a {
    color: white;
    text-transform: capitalize;
}

.main-header.style-three .btn-1 {
    background: var(--secondary-color);
}
.main-header .upper-head {
    border-bottom: 1px solid white;
    padding-bottom: 0.1rem;
}







.property__image__box img{
    max-height: 250px;
    width: 100%;
    object-fit: contain;
}

.card-img-top{
    max-height: 250px;
    width: 100%;
    object-fit: cover;
}